<template>
  <validation-observer ref="obs" v-slot="{  }">
    <v-form @submit.prevent="submit">
      <p class="headline pt-3">Edit Strategy</p>
      <v-sheet elevation="2" class="pa-4">
        <v-row>
          <v-col sm="6">
            <validated-select-field
              :items="inputTypes"
              item-value="inputKey"
              item-text="typeName"
              rules="required"
              v-model="strategy.inputTypeName"
              label="Strategy Trigger"
              hint="What input type should trigger this strategy?"
              persistent-hint
              return-object
              disabled
            ></validated-select-field>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-col sm="6">
            <validated-text-field
              autofocus
              v-model="strategy.name"
              label="Strategy Name"
              rules="required"
              required
            />
          </v-col>
          <v-col sm="6">
            <validated-text-field
              v-model="strategy.description"
              label="Strategy Description"
              rules="required"
            ></validated-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <validated-select-field
              :items="frequencies"
              item-value="id"
              item-text="text"
              rules="required"
              v-model="strategy.executionFrequency"
              label="Execution Frequency"
              hint="How often should the rule be executed?"
              persistent-hint
            ></validated-select-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="12">
            <v-card outlined>
              <v-card-text id="divRuleEditor" ref="divRuleEditor"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              type="submit"
              color="primary"
              :disabled="ruleInvalid"
              class="mr-5"
              >Save</v-btn>
            <v-btn
              @click="saveAs"
              color="accent"
              class="mr-5"
              :disabled="ruleInvalid"
              >Save As</v-btn>
            <v-btn color="primary" @click="validateRule">Validate</v-btn>
            <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-form>

    <v-row justify="center">
      <v-dialog v-model="showConfirm" width="500">
        <v-card>
          <v-card-title class="headline"
            >Enter new name for strategy</v-card-title
          >
          <v-card-text>
            <v-container>
              <v-text-field v-model="saveAsName"></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="handleCancel" text colr="blue darken-1"
              >Cancel</v-btn
            >
            <v-btn @click="handleOk" text color="blue darken-1">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </validation-observer>
</template>


 <style lang="scss">
  div.ceRule {
    min-height: 20vh;
  }
</style>

<script>
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import api from "./_api";
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    "validation-observer": ValidationObserver,
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField,
  },

  data() {
    return {
      ruleInvalid: true,
      siteId: null,
      inputTypes: [],
      ruleEditor: null,
      strategyName: "",
      strategyId: null,
      strategy: {},
      frequencies: [
        { id: "EveryFiveMinutes", text: "Every 5 minutes" },
        { id: "EveryTenMinutes", text: "Every 10 minutes" },
        { id: "EveryFifteenMinutes", text: "Every 15 minutes" },
        { id: "EveryThirtyMinutes", text: "Every 30 minutes" },
        { id: "EveryHour", text: "Every hour" },
        { id: "EveryDay", text: "Every day" },
      ],
      selectedFrequency: null,
      strategyDescription: "",
      selectedInput: null,
      showConfirm: false,
      saveAsName: null
    };
  },

  async created() {
    //load static codeeffects assets
    let codeEffectsScript = document.createElement("script");
    codeEffectsScript.setAttribute("src", "/js/codeeffects.editor.js");
    document.head.appendChild(codeEffectsScript);

    let cssLink1 = document.createElement('link');
    cssLink1.setAttribute('rel', 'stylesheet');
    cssLink1.setAttribute('href', '/js/codeeffects.common.css');
    document.head.appendChild(cssLink1);

    let cssLink2 = document.createElement('link');
    cssLink2.setAttribute('rel', 'stylesheet');
    cssLink2.setAttribute('href', '/js/codeeffects.gray.css');
    document.head.appendChild(cssLink2);

    this.strategyId = this.$route.params.strategyId;
    this.siteId = this.$route.params.siteId;
    //this.strategyName = this.$route.params.strategyName;
    this.strategy = await api.getStrategyById(this.siteId, this.strategyId);
    this.inputTypes = await api.getInputTypes();
    this.editorSettings = await api.getEditorSettingsForStrategy(
      this.siteId,
      this.strategy.name
    );
    // there's a window.$rule, so we'll assign it to a local variable to make things easier
    let $rule = window["$rule"];
    //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
    this.ruleEditor = $rule.Context.getControl("divRuleEditor");
    if (this.ruleEditor != null) this.ruleEditor.dispose();
    $rule.Context.clear();

    //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
    this.ruleEditor = $rule.init(this.editorSettings.editorData);
    this.ruleEditor.clear();
    this.ruleEditor.loadSettings(this.editorSettings.sourceData);
    this.ruleEditor.loadRule(this.editorSettings.ruleData);
    //this.ruleEditor.disable();

    this.ruleInvalid = false;

    console.log(`Strategy ${this.strategyName}`, this.strategy);

    //loadInput(this.strategy.inputTypeName)
  },

  methods: {
    async submit() {
      // TODO: validation
      let valid = await this.$refs.obs.validate();
      if (!valid) {
        return;
      }

      let ruleContent = this.ruleEditor.extract();
      await api.updateStrategy(
        this.strategy.id,
        this.strategy.siteId,
        this.strategy.name,
        this.strategy.description,
        this.strategy.inputTypeName,
        this.strategy.executionFrequency,
        ruleContent
      );
      this.$toast.show("Successfully updated your strategy.", null, 'success');
      this.$router.push({ name: "StrategiesIndex" });
    },

    saveAs() {
      this.showConfirm = true;
      this.saveAsName = this.strategy.name + " (copy)";
    },
    handleOk() {
      this.showConfirm = false;
      this.strategy.id = null;
      this.strategy.name = this.saveAsName;
      this.postStrategy();
    },
    handleCancel() {
      this.saveAsName = this.strategy.name;
      this.showConfirm = false;
    },
    async postStrategy() {
      // TODO: validation
      let valid = await this.$refs.obs.validate();
      if (!valid) {
        return;
      }
      let ruleContent = this.ruleEditor.extract();
      await api.saveStrategy(
        this.strategy.siteId,
        this.strategy.name,
        this.strategy.description,
        this.strategy.inputTypeName,
        this.strategy.executionFrequency,
        ruleContent
      );
      this.$toast.show("Successfully saved as strategy.", null, "success");
      this.$router.push({ name: "StrategiesIndex" });
    },

    async validateRule() {
      let valid = await this.$refs.obs.validate();
      if (!valid) {
        return;
      }
      let ruleContent = this.ruleEditor.extract();
      let validationResult = await api.validateRule(
        this.siteId,
        this.strategy.inputTypeName,
        this.strategy.name,
        this.strategy.description,
        this.strategy.executionFrequency,
        ruleContent
      );

      console.log(validationResult);
      if (validationResult.empty) {
        this.$toast.show("Rule cannot be empty.", null, "error");
        this.ruleInvalid = true;
        return;
      }

      if (!validationResult.valid) {
        try {
        this.ruleEditor.loadInvalids(validationResult.clientInvalidData);
        } catch {
          this.$toast.show(validationResult.clientInvalidData, null, "error");
        }
        this.ruleInvalid = true;
      } else {
        this.$toast.show("Rule is valid and can be saved.", null, 'success');
        this.ruleInvalid = false;
      }
    },

    async loadInput(input) {
      console.log(input);
      this.editorSettings = await api.getEditorSettingsForInput(this.siteId, input);
      // there's a window.$rule, so we'll assign it to a local variable to make things easier
      let $rule = window["$rule"];
      //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
      this.ruleEditor = $rule.Context.getControl("divRuleEditor");
      if (this.ruleEditor != null) this.ruleEditor.dispose();
      $rule.Context.clear();

      //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
      this.ruleEditor = $rule.init(this.editorSettings.editorData);
      this.ruleEditor.clear();
      this.ruleEditor.loadSettings(this.editorSettings.sourceData);
      // this.ruleEditor.loadRule(this.editorSettings.ruleData);
      let $re = this.ruleEditor;
      // TODO: docs for extract claim to show if it's valid, but really don't
      //       Keep an eye on their answer to my August 27, 2021 07:54 PM questions
      //       https://codeeffects.com/Doc/Business-Rule-Ajax-Api
      //       Their demo tests the rule on save
      this.ruleEditor.onBlur(function () {
        console.log(`Editor changed: ${$re.isAltered()}`);
        // console.log("Editor extract:", $re.extract());
      });
      // TODO: add a testing mode. Waiting to see how to implement test actions
      //       Wait for reply to comment August 26, 2021 05:37 PM
      //       https://codeeffects.com/Doc/Business-Rule-Execution-Type
    },

    handleCancelCrud() {
      this.$router.push({name: "StrategiesIndex"});
    }

  },
};
</script>
